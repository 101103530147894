/**
 * `404` typically displays a 404 screen for paths that don't match one of the
 * pages built by Gatsby. However, we also use this component to render previews
 * of repeatable content type documents that have never been published. Unpublished
 * documents aren't yet processed by Gatsby so they get routed to this 404 template whenever
 * a content admin tries to preview them. To resolve this, we're checking to see
 * if a preview query param exists on the url, and if so load the appropriate template.
 */

import React, { useEffect, useState } from "react";
import qs from "query-string";
import { useAppState } from "@state";
import use404PageData from "@staticQueries/Error404Query";
import DefaultPageContainer from "@layout/DefaultPageContainer";
import loadable from "@loadable/component";

const PageHero = loadable(() => import("@organisms/PageHero"));
const EndofPageCta = loadable(() => import("@organisms/EndofPageCta"));

const Page = loadable(() => import("@dynamicQueries/PagesDefaultQuery"));
const Resource = loadable(() => import("@dynamicQueries/ResourcesQuery"));
const CaseStudy = loadable(() =>
  import("@dynamicQueries/CaseStudiesDefaultQuery")
);
const Article = loadable(() => import("@dynamicQueries/ArticlesDefaultQuery"));
const Event = loadable(() => import("@dynamicQueries/EventsDefaultQuery"));

const NotFoundPage = ({ pageContext, location, ...rest }) => {
  // eslint-disable-next-line no-unused-vars
  const [loaded, setLoaded] = useState(false);
  const [{ layout }, dispatch] = useAppState();
  const { hero } = use404PageData();

  useEffect(() => {
    // set preview mode if preview data is detected
    const {
      "x-craft-live-preview": xCraftLivePreview,
      "x-craft-preview": xCraftPreview,
    } = qs.parse(location.search);
    if (xCraftLivePreview || xCraftPreview) {
      dispatch({ type: "setPreviewMode", previewMode: "loading" });
    } else {
      setLoaded(true);
    }
  }, []);

  if (layout.previewMode) {
    // Use the appropriate page query based on the url
    // Note we need to manually set a `pageContext` prop and supply
    // it with a slug which we're grabbing from the last segment of the url.
    // This is used in the preview query to fetch the appropriate entry
    const split = location.pathname.split("/");
    const firstSegment = split[1];
    const lastSegment = split[split.length - 1];
    const props = { ...rest, location, pageContext: { slug: lastSegment } };

    // set up queries here for preview
    switch (firstSegment) {
      case "resources":
        return <Resource {...props} />;
      case "case-studies":
        return <CaseStudy {...props} />;
      case "events":
        return <Event {...props} />;
      case "articles":
        return <Article {...props} />;
      default:
        return <Page {...props} />;
    }
  }

  if (process.env.GATSBY_IN_DEV) {
    return (
      <DefaultPageContainer {...rest} flex={false}>
        <PageHero
          {...hero}
          heading="The site is still in progress."
          descriptor="Don't worry. Chances are the page you are looking for is not done yet."
          separate={false}
          patternColor="pink"
        />
        <EndofPageCta />
      </DefaultPageContainer>
    );
  }

  return (
    <DefaultPageContainer {...rest} flex={false}>
      <PageHero {...hero} separate={false} color="teal" patternColor="pink" />
      <EndofPageCta />
    </DefaultPageContainer>
  );
};

export default NotFoundPage;
